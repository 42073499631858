import React, { createContext, useContext, useRef, useState } from "react";

// Create a context
const AudioContext = createContext();

// Context provider component
export const AudioProvider = ({ children }) => {
  const [currentAudio, setCurrentAudio] = useState(null); // Track the URL of the currently playing audio
  const audioRefs = useRef({}); // Use an object to store refs by audio URL

  // Function to handle playing and pausing audio
  const handlePlayPause = (url) => {
    // If there is already a playing audio and it's the same URL, pause it
    if (currentAudio === url) {
      audioRefs.current[url].pause();
      setCurrentAudio(null);
    } else {
      // Pause the currently playing audio if there is one
      if (currentAudio !== null) {
        audioRefs.current[currentAudio].pause();
      }

      // If the audio element does not exist in refs, create it
      if (!audioRefs.current[url]) {
        const audio = new Audio(url); // Create new audio element
        audioRefs.current[url] = audio; // Store the reference
      }

      // Play the new audio
      audioRefs.current[url].play();
      setCurrentAudio(url); // Set the current audio to the new URL
    }
  };

  return (
    <AudioContext.Provider value={{ audioRefs, currentAudio, handlePlayPause }}>
      {children}
    </AudioContext.Provider>
  );
};

// Custom hook to use audio context
export const useAudioContext = () => useContext(AudioContext);
