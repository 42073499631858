import React from "react";

const TestingContactUs = () => {
  return (
    <>
      <h2
        id="contact-us"
        class="font-sans font-extrabold break-normal text-gray-700 px-2 pb-8 text-xl "
      >
        Support and Contact Information
      </h2>
      <div class="p-8 mt-6 lg:mt-0 leading-normal rounded shadow bg-white mb-12">
        <p class="text-gray-700 text-base mb-6">
          If you have questions or need assistance:
        </p>
        <ul className="list-disc list-inside text-gray-700 text-base mb-6 mt-6">
          <li>
            <span class="font-semibold">Email: </span>
            <a
              href="mailto:hello@readwithjoey.com"
              className="text-blue underline"
            >
              hello@readwithjoey.com
            </a>
          </li>
          <li>
            <span class="font-semibold">Phone: </span>
            <a href="tel:+61423681032" className="text-blue underline">
              +61 423 681 032
            </a>
          </li>
          <li>
            <span class="font-semibold">Instagram: </span>
            <a
              href="https://www.instagram.com/readwithjoey"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue underline"
            >
              Joey Books
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};

export default TestingContactUs;
