import React from "react";

const TestingThanks = () => {
  return (
    <>
      <div className="p-8 mt-6 lg:mt-0 leading-normal rounded shadow bg-white mb-12">
        <h4 className="font-[600] break-normal text-gray-900 text-xl mb-2">
          Thank You
        </h4>
        <p className="text-gray-700 text-base mb-6 mt-6">
          We appreciate your time and valuable insights. Your feedback will help
          us create a better reading experience for children everywhere!
          <br />
          <br />
          <span className="font-semibold">Best Regards, </span>
          <br />
          Cal & Tory
        </p>
      </div>
    </>
  );
};

export default TestingThanks;
