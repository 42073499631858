import { motion } from "framer-motion";

import { useMediaQuery } from "react-responsive";

const TestimonialCard = ({ testiminial, index }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const isEvenIndex = index % 2 === 0;

  var rotateOffset = isEvenIndex ? 5 : -6;

  if (isMobile) {
    rotateOffset = rotateOffset / 5;
  }
  return (
    <motion.div
      className={`group relative h-fit min-h-[400px] md:w-[450px] mx-6 md:overflow-hidden  rounded-3xl  shadow-l flex flex-col bg-white  z-[${index}] `}
      style={{
        rotate: rotateOffset,
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
      }}
    >
      <div className=" h-[200px] w-full flex p-6 flex-row">
        <div className="flex-1  flex justify-start items-center text-[6rem] capitalize font-[600]">
          {testiminial.name[0]}
        </div>
        <div className="flex-1  flex justify-center items-center h-full">
          <img
            src={`/profiles/${testiminial.profileImage}`}
            alt={`${testiminial.name} profile`}
            className="object-contain h-full w-full"
          />
        </div>
      </div>
      <div className="flex justify-start items-center  flex-col p-3 pb-6">
        <h1 className="text-3xl text-black flex justify-start w-full font-[600]">
          {testiminial.name}
        </h1>
        <p className="text-black text-base flex justify-start w-full pb-3 pt-1">
          {testiminial.credentials}
        </p>
        <p className="text-black text-base flex flex-row items-start">
          <img
            src="https://images.readwithjoey.com/joey/website/quotations.webp"
            alt="Quotation"
            className="  mr-2 object-contain h-6 -translate-y-2 "
          />
          {testiminial.content}
        </p>
      </div>
    </motion.div>
  );
};

export default TestimonialCard;
