class BookModel {
  constructor(title, cover, audio_sample) {
    this.title = title;
    this.cover = cover;
    this.audio_sample = audio_sample;
  }
}

const books = [
  new BookModel("Crocs Stole my socks", "Crocs_Stole_my_socks.png", ""),
  new BookModel("Little Ned", "LittleNed_CVR.png", ""),
  new BookModel("Maudie And Bear", "MaudieAndBear.png", ""),
  new BookModel("Pigusus", "pigusus.jpg", "PigususSample.mp3"),
  new BookModel(
    "Sing Me The Summer",
    "SingMeTheSummer.jpg",
    "SingMeTheSummerSample.mp3"
  ),
  new BookModel(
    "Stellarphant",
    "Stellarphant_Cover.png",
    "StellarphantSample.mp3"
  ),
  new BookModel("Sunday Skating", "SundaySkating.png", ""),
  new BookModel(
    "A Sausage Went For A Walk",
    "a_sausage_went_for_a_walk.png",
    "ASausageWentForAWalkSample.mp3"
  ),
  new BookModel(
    "ABC Of Australian Animals",
    "abc_of_australian_animals.png",
    "AbcAustralianAnimalsSample.mp3"
  ),
  new BookModel(
    "Archie And The Bear",
    "archie_and_the_bear.png",
    "ArchieAndTheBearSample.mp3"
  ),
  new BookModel(
    "At The Dog Park",
    "at_the_dog_park.png",
    "AtTheDogParkSample.mp3"
  ),
  new BookModel("Backyard Birds", "backyard_birds.png", ""),
  new BookModel("Claris Paris", "claris-paris-cover.png", "ClarisSample.mp3"),
  new BookModel(
    "Dancing With Grandma",
    "dancing_with_grandma.jpg",
    "DancingWithGrandmaSample.mp3"
  ),
  new BookModel("Gand Party Plan", "gand_party_plan.png", ""),
  new BookModel(
    "Granny Grommet And Me",
    "granny_grommet_and_me.jpg",
    "GrannyGrommetAndMeSample.mp3"
  ),
  new BookModel("In The Rockpool", "in_the_rockpool.png", ""),
  new BookModel(
    "Jetty Jumping",
    "jetty-jumping-cover.png",
    "JettyJumpingSample.mp3"
  ),
  new BookModel("Jumping Joeys", "jumping_joeys.png", ""),
  new BookModel(
    "Koalas Stole My Undies",
    "koalas_stole_my_undies.png",
    "KoalaStoleMyUndiesSample.mp3"
  ),
  new BookModel(
    "Lets Build A Boat",
    "lets_build_a_boat.png",
    "LetsBuildABoatSample.mp3"
  ),
  new BookModel(
    "Lets Count Wildflowers",
    "lets_count_wildflowers.jpg",
    "LetsCountWildFlowersSample.mp3"
  ),
  new BookModel("Mermaid", "mermaid.jpg", "MermaidSample.mp3"),
  new BookModel(
    "One Sheet Two Sheep",
    "one_sheet_two_sheep.png",
    "OneSheepTwoSheepSample.mp3"
  ),
  new BookModel(
    "Quokka Finds A Friend",
    "quokka_finds_a_friend.jpg",
    "QuokkaFindAFriendSample.mp3"
  ),
  new BookModel("Say Hooray", "say_hooray.jpg", "SayHoraySample.mp3"),
  new BookModel(
    "The Best Hiding Place",
    "the_best_hiding_place.png",
    "TheBestHidingPlaceSample.mp3"
  ),
  new BookModel("The Feather", "the_feather.jpg", "TheFeatherSample.mp3"),
  new BookModel(
    "The Stone Lion",
    "the_stone_lion.png",
    "TheStoneLionSample.mp3"
  ),
  new BookModel("The Very Noisy Baby", "the_very_noisy_baby.png", ""),
  new BookModel(
    "The Wonders Of Never Giving Up",
    "the_wonders_of_never_giving_up.png",
    "TheWondersOfNeverGivingUpSample.mp3"
  ),
  new BookModel("Too Many Cats", "too_many_cats.png", "TooManyCatsSample.mp3"),
  new BookModel("Unicorn", "unicorn.jpg", "UnicornSample.mp3"),
  new BookModel("Water Witcher", "water_witcher.png", "WaterWitcherSample.mp3"),
  new BookModel(
    "What Colour Is The Sea",
    "what_colour_is_the_sea.png",
    "WhatColourIsTheSeaSample.mp3"
  ),
  new BookModel(
    "When I Can Fly",
    "when_i_can_fly.jpg",
    "WhenICanFlySample.mp3"
  ),
  new BookModel(
    "Where Do The Stars Go",
    "where_do_the_stars_go.jpg",
    "WhereDoTheStarsGoSample.mp3"
  ),
  new BookModel(
    "Why I Love Australia",
    "why_i_love_australia.png",
    "WhyILoveAustraliaSample.mp3"
  ),
  new BookModel(
    "Wombat Cant Sing",
    "wombat_cant_sing.jpg",
    "WombatCantSingsample.mp3"
  ),
];

export default books;
