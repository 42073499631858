import React from "react";

const FAQSubscriptionSection = () => {
  return (
    <>
      <h2
        id="subscribing"
        class="font-sans font-extrabold break-normal text-gray-700 px-2 pb-8 text-xl "
      >
        Subscribing
      </h2>
      <div class="p-8 mt-6 lg:mt-0 leading-normal rounded shadow bg-white mb-12">
        <h4 class="font-[600] break-normal text-gray-900 text-xl mb-2">
          Will I be charged during the beta test?
        </h4>
        <p class="text-gray-700 text-base mb-6">
          No, you will not be charged during the beta testing period. When going
          to subscribe Apple Pay and Google Play will tell you that this is a
          test subscription and should always be approved.
        </p>
        <div className="flex flex-row overflow-hidden max-w-250px justify-center">
          <img
            src="/instructions/ios_subscribing_free.jpg"
            className="object-contain max-h-[250px] md:h-[450px] mx-5"
            alt="iOS subscription screen"
          />
          <img
            src="/instructions/JoeyGooglePaySubscription.jpg"
            className="object-contain max-h-[250px] md:h-[450px] mx-5"
            alt="Google Play subscription screen"
          />
        </div>
        <h4 class="font-[600] break-normal text-gray-900 text-xl mb-2 mt-6">
          How do I subscribe without Apple or Google Pay?
        </h4>
        <p class="text-gray-700 text-base mb-6">
          On the subscription page, navigate to “code” tab and enter “beta”.
          This should bypass the need to use a payment method. Using these
          payment methods is preferred as it does allow us to better test these
          systems.
        </p>
        <div className=" overflow-hidden max-w-250px justify-center h-[450px]">
          <video
            controls
            className="object-contain max-h-[450px]"
            src="https://images.readwithjoey.com/joey/website/assets/subscribing_bypass.mp4"
            alt="Bypassing payment method"
          />
        </div>
      </div>
    </>
  );
};

export default FAQSubscriptionSection;
