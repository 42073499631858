import React from "react";

const TechincalSupportSection = () => {
  return (
    <>
      <h2
        id="techincal-support"
        class="font-sans font-extrabold break-normal text-gray-700 px-2 pb-8 text-xl "
      >
        Technical Support
      </h2>
      <div class="p-8 mt-6 lg:mt-0 leading-normal rounded shadow bg-white mb-12">
        <h4 class="font-[500] break-normal text-gray-900 text-xl mb-2">
          The app is crashing or freezing. What should I do?
        </h4>
        <ul className="list-disc list-inside text-gray-700 text-base mb-6 mt-6">
          <li>
            <span class="font-semibold">Restart the App:</span> Close the app
            completely and reopen it.
            <ul className=" list-inside ml-8 mt-1">
              <li>
                <span>
                  For iOS find detailed instruction{" "}
                  <a
                    href="https://support.apple.com/en-au/109359"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue underline"
                  >
                    here.
                  </a>
                </span>
              </li>
              <li>
                <span>
                  For Android find detailed instructions{" "}
                  <a
                    href="https://support.google.com/android/answer/9079646?hl=en#zippy=%2Cclose-apps"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue underline"
                  >
                    here.
                  </a>
                </span>
              </li>
            </ul>
          </li>
          <li>
            <span class="font-semibold">Reinstall the App:</span> Uninstall and
            reinstall Joey Books through TestFlight or PlayStore.
          </li>
          <li>
            <span class="font-semibold">Contact Support:</span> If the issue
            persists, please email us at{" "}
            <a
              href="mailto:hello@readwithjoey.com"
              className="text-blue underline"
            >
              hello@readwithjoey.com
            </a>{" "}
            with details about the problem.
          </li>
        </ul>

        <hr class="bg-gray-300 my-12" />

        <h4 class="font-[500] break-normal text-gray-900 text-xl mb-2 mt-6">
          I can't hear any sound. How do I fix this?
        </h4>
        <p class="text-gray-700 text-base mb-6 mt-6">
          <ul className="list-decimal list-inside text-gray-700 text-base mb-6 mt-6">
            <li>
              <span class="font-semibold">Check Volume Levels:</span> Make sure
              your device's volume is turned up and not muted.
            </li>
            <li>
              <span class="font-semibold">Silent Mode: </span>
              Ensure your device isn't in silent or vibrate mode.
            </li>
            <li>
              <span class="font-semibold">Headphones: </span> Try using
              headphones to see if the issue is with the device's speakers.
            </li>
          </ul>
        </p>
      </div>
    </>
  );
};

export default TechincalSupportSection;
