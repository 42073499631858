import React, { useRef } from "react";
import { useInView } from "framer-motion";

const UserStory = () => {
  const containerRef = useRef(null);
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  return (
    <section
      className="w-full overflow-hidden"
      ref={containerRef}
      style={{
        transform: isInView ? "none" : "translateY(50px)",
        opacity: isInView ? 1 : 0,
        transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1)  ",
      }}
    >
      <div className="mx-auto max-w-7xl px-4 py-12 md:py-[20vh] sm:px-8 flex items-center flex-col justify-center relative overflow-hidden">
        <h2 className="mb-16 text-center text-3xl sm:mb-8 sm:text-4xl  z-10 font-[600]">
          Jumpstart reading with
          <img
            src="https://images.readwithjoey.com/joey/logos/joey-blue.webp"
            alt="Joey Logo"
            className="inline-block h-14 my-3 ml-2"
          />
        </h2>

        <div
          className="mb-16 flex flex-col justify-between space-y-8 md:flex-row md:space-x-16 md:space-y-0 font-[500]"
          ref={ref}
        >
          <div className="flex flex-1 flex-col items-center justify-start text-center">
            <div className="flex h-80 md:h-auto flex-col items-center justify-end">
              <img
                src="https://images.readwithjoey.com/joey/website/kids_demo.webp"
                className="mb-8 md:w-64 w-full h-full object-contain"
                alt="What I've Read"
              />
            </div>
            <h3 className="mb-4 text-2xl sm:w-56 sm:text-xl">
              Engage you child's imagination
            </h3>
          </div>
          <div className="flex flex-1 flex-col items-center justify-start text-center">
            <div className="flex h-80 md:h-auto flex-col items-center justify-end">
              <img
                src="https://images.readwithjoey.com/joey/website/reading_demo_dive.webp"
                className="mb-8 md:w-64 w-full h-full object-contain -translate-x-5"
                alt="What I've Read"
              />
            </div>
            <h3 className="mb-4 text-2xl sm:w-56 sm:text-xl">
              Dive into a sea of stories and grow your vocabulary
            </h3>
          </div>
          <div className="flex flex-1 flex-col items-center justify-start text-center">
            <div className="flex h-80 md:h-auto flex-col items-center justify-end">
              <img
                src="assets/reading_demo.gif"
                className="mb-8 md:w-64  h-full object-contain"
                alt="What I've Read"
              />
            </div>
            <h3 className="mb-4 text-2xl sm:w-56 sm:text-xl">
              Boost your child's comprehension
            </h3>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UserStory;
