import React from "react";

const FAQHeader = () => {
  return (
    <header class="bg-blue w-full flex items-center h-full justify-center shadow rounded-t-lg">
      <a href="/">
        <img
          src="/assets/FAQ-Header.jpg"
          alt="Joey Footer"
          class="md:h-[200px] object-contain"
        />
      </a>
    </header>
  );
};

export default FAQHeader;
