import React from "react";
import TestingContactUs from "../components/BetaTesting/sections/testingContact";
import FAQIntro from "../components/BetaFAQ/sections/faqIntro";
import FAQContentTable from "../components/BetaFAQ/FAQContentTable";
import FAQSubscriptionSection from "../components/BetaFAQ/sections/subscriptionFAQ";
import FAQHeader from "../components/BetaFAQ/FAQHeader";
import TechincalSupportSection from "../components/BetaFAQ/sections/techincalSupport";

const BetaTestingFAQ = () => {
  return (
    <body className="bg-gray-100 min-h-screen text-gray-900 tracking-wider leading-normal  -z-20">
      <FAQHeader />
      <div className="container w-full mx-auto px-2 pt-8 lg:pt-8 mt-8 ">
        {/* Sidebar for large screens */}
        <div className="hidden lg:block fixed w-1/5 px-6 text-xl text-gray-800 leading-normal   ">
          <FAQContentTable />
        </div>

        {/* Main content area */}
        <div className="flex flex-col lg:ml-[20%]">
          <div className="w-full">
            <FAQIntro />
            <div className="block lg:hidden order-2 w-full px-6 text-xl text-gray-800 leading-normal">
              <FAQContentTable />
            </div>
            <hr className="bg-gray-300 my-12" />
            {/* Sections go here */}
            <FAQSubscriptionSection />
            <hr className="bg-gray-300 my-12" />
            <TechincalSupportSection />
            <hr className="bg-gray-300 my-12" />

            <TestingContactUs />
          </div>
        </div>
      </div>
      <footer className="bg-blue w-full flex items-center h-full justify-center shadow rounded-t-lg">
        <img
          src="/assets/JoeyFooter.jpg"
          alt="Joey Footer"
          className="md:h-[200px] object-contain"
        />
      </footer>
    </body>
  );
};
export default BetaTestingFAQ;
