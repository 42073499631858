import React from "react";

const TestingFeedback = () => {
  return (
    <>
      <h2
        id="feedback"
        class="font-sans font-extrabold break-normal text-gray-700 px-2 pb-8 text-xl "
      >
        Providing Feedback
      </h2>
      <div class="p-8 mt-6 lg:mt-0 leading-normal rounded shadow bg-white mb-12">
        <h4 class="font-[500] break-normal text-gray-900 text-xl mb-2">
          Feedback Form
        </h4>
        <p class="text-gray-700 text-base mb-5">
          Please submit your feedback by completing our online survey:
        </p>
        <a
          class="font-bold text-blue underline text-2xl "
          href="https://41ys65jlltc.typeform.com/to/aeVBXBYV"
          target="_blank"
          rel="noopener noreferrer"
        >
          Joey Books Beta Tester Feedback Form
        </a>
        <hr class="bg-gray-300 my-12" />
        <h4 class="font-[500] break-normal text-gray-900 text-xl mb-2 mt-6">
          Feedback Guidelines
        </h4>
        <p class="text-gray-700 text-base mb-6 mt-6">
          <ul className="list-disc list-inside text-gray-700 text-base mb-6 mt-6">
            <li>
              <span class="font-semibold">Be Specific:</span> Provide detailed
              descriptions of your experience.
            </li>
            <li>
              <span class="font-semibold">Include Screenshots: </span>
              If possible, attach screenshots of any issues.
            </li>
            <li>
              <span class="font-semibold">Be Honest: </span> Your candid
              feedback is essential for improvements.
            </li>
          </ul>
        </p>
        <hr class="bg-gray-300 my-12" />
        <h4 class="font-[500] break-normal text-gray-900 text-xl mb-2 mt-6">
          Feedback Topics
        </h4>
        <p class="text-gray-700 text-base mb-6 mt-6">
          <ul className="list-disc list-inside text-gray-700 text-base mb-6 mt-6">
            <li>Overall experience</li>
            <li>Usability and navigation</li>
            <li>Content engagement</li>
            <li>Visual and audio elements</li>
            <li>Technical issues</li>
            <li>Suggestions for improvement</li>
          </ul>
        </p>
        <p class="text-gray-700 text-base  mt-6">
          Deadline for feedback submission:{" "}
          <span className="font-semibold">30th of September</span>
        </p>
      </div>
    </>
  );
};

export default TestingFeedback;
