import { motion, useTransform, useScroll } from "framer-motion";
import { useRef } from "react";
import testimonials, {
  children_favorites_images,
} from "../../../models/testimonial";
import TestimonialCard from "./testimonial_card";
import { useMediaQuery } from "react-responsive";

export default function HorizontalTestimonials() {
  return (
    <section className="bg-white">
      <HorizontalScrollCarousel />
    </section>
  );
}

const HorizontalScrollCarousel = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const testimonialRef = useRef(null);
  const { scrollYProgress: testimonialScrollYProgress } = useScroll({
    target: testimonialRef,
    offset: ["start start", "end end"],
  });
  const testiminialCardHeight = 500;
  const testiminialCardWidth = 450;
  const viewWidth = window.innerWidth;
  const scrollLength = testimonials.length * testiminialCardWidth;
  console.log(`scrollLength: ${scrollLength}`);
  console.log(`screen width: ${viewWidth}`);

  var percentRemaining = (1 - (testiminialCardWidth * 2) / scrollLength) * -100;

  console.log(`percentRemaining: ${percentRemaining}`);
  if (testiminialCardWidth * 2 > viewWidth) {
    percentRemaining = (1 - viewWidth / 2 / scrollLength) * -100;
  }
  var percentGap = ((viewWidth * 0.5) / scrollLength) * 100;
  console.log(`percentStart: ${percentGap}`);

  console.log(percentRemaining);
  const x = useTransform(
    testimonialScrollYProgress,
    [0, 1],
    [percentGap + "%", -(100 - percentGap) + "%"]
  );
  const windowHeight = window.innerHeight;
  console.log(windowHeight);
  var isHideKids = false;
  if (windowHeight < testiminialCardHeight + 400 || isMobile) {
    isHideKids = true;
  }

  const childPositions = [
    " md:absolute md:translate-y-[-375px] md:-rotate-12 -left-36 h-[200px] drop-shadow-lg" +
      (isHideKids ? " hidden" : ""),
    "md:absolute md:translate-y-[-375px] md:rotate-[7deg] right-1/2  h-[200px]  drop-shadow-lg" +
      (isHideKids ? " hidden" : ""),
    "md:absolute md:translate-y-[-375px] md:-rotate-[9deg] right-[10%] h-[200px] drop-shadow-lg " +
      (isHideKids ? " hidden" : ""),
  ];
  const childImages = children_favorites_images.map((image, index) => {
    return (
      <img
        key={index}
        src={image}
        alt="Child Favorites"
        className={"  flex " + childPositions[index]}
      />
    );
  });

  return (
    <section
      ref={testimonialRef}
      className={`md:relative md:h-[250vh]  md:py-0 w-full  py-20 justify-center 100vh overflow-hidden md:overflow-visible`}
      // what you scroll distance
    >
      <div className="md:hidden  md:absolute md:top-10 md:left-0 md:right-0 md:bottom-0 md:z-10 md:overflow-hidden md:h-full md:w-full">
        <h1 className="text-4xl text-center font-[600]">Testimonials</h1>
      </div>
      <div
        className={`md:sticky md:top-0 flex h-full w-full pb-5 pt-5 md:min-h-[${testiminialCardHeight}px] md:h-screen items-center md:overflow-hidden`}
      >
        <motion.div
          style={isMobile ? { x: 0 } : { x }}
          className="flex flex-col md:w-auto w-full   md:flex-row gap-4 items-center justify-center h-full "
        >
          {!isMobile && childImages}
          <img
            src="plane.jpg"
            alt="Plane "
            className="hidden md:flex  absolute translate-y-[150px]  -right-[350px] md:w-[700px] "
          />
          {testimonials.map((testiminial, index) => {
            return (
              <TestimonialCard
                testiminial={testiminial}
                key={testiminial.name}
                index={index}
                containerRef={testimonialRef}
              />
            );
          })}
        </motion.div>
      </div>
    </section>
  );
};
