import React, { useRef, useState } from "react";
import {
  motion,
  useScroll,
  useTransform,
  useMotionValueEvent,
} from "framer-motion";
import { useMediaQuery } from "react-responsive";
const ProductSectionList = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const [sectionIndex, setSectionIndex] = useState(0);
  const containerRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ["start start", "end end"],
  });

  const images = [
    "phone/iphone_mockup_landscape_1.png",
    "phone/iphone_mockup_landscape_2.png",
    "phone/iphone_mockup_landscape_3.png",
  ];

  // Define the transform values for the three scroll points

  const scrollIndexEvents = useTransform(
    scrollYProgress,
    [0, 0.5, 1],
    [0, 1, 2]
  ); // Three scroll points
  useMotionValueEvent(scrollIndexEvents, "change", (latest) => {
    setSectionIndex(Math.round(latest));
  });
  if (isMobile) {
    return (
      <section className="w-full  bg-blue min-h-screen flex justify-center items-center overflow-hidden py-24 ">
        <div className="  max-w-6xl">
          <motion.div className="sticky top-0">
            <div className="w-full md:h-screen flex flex-col md:flex-row">
              <div className="flex-1 h-full flex justify-center items-center">
                <motion.div>
                  <motion.img
                    key={2} // This ensures the image is re-rendered when the sectionIndex changes
                    src={images[2]}
                    className="md:w-[50vw] w-full"
                    alt="Product Demo"
                    initial={{ opacity: 0 }} // Start with opacity 0
                    animate={{ opacity: 1 }} // Animate to opacity 1
                    exit={{ opacity: 0 }} // Exit with opacity 0
                    transition={{ duration: 0.5 }} // Duration of the fade animation
                  />
                </motion.div>
              </div>
              <div className="flex-1 h-full flex flex-col justify-center items-center ">
                <motion.div>
                  <h1 className="text-3xl text-center text-white md:text-5xl mb-10 font-[600] ">
                    Screen time with purpose
                  </h1>
                </motion.div>
                <motion.div
                  animate={{
                    opacity: 1,
                  }}
                  transition={{ duration: 0.5 }}
                  style={{
                    borderRadius: "20px",
                    backgroundColor: "white",
                    padding: "30px",
                    marginTop: "20px",
                    marginBottom: "20px",
                    maxWidth: "400px",
                  }}
                >
                  <p className="text-xl text-center text-black  font-[500]">
                    Safe, fun, and crafted just for kids aged 3-7!
                  </p>
                </motion.div>
                <motion.div
                  animate={{
                    opacity: 1,
                  }}
                  transition={{ duration: 0.5 }}
                  style={{
                    borderRadius: "20px",
                    backgroundColor: "white",
                    padding: "30px",
                    marginTop: "20px",
                    marginBottom: "20px",
                    maxWidth: "400px",
                  }}
                >
                  <p className="text-xl text-center text-black font-[500]">
                    Approved by teachers, speech pathologists and
                    paediatricians.
                  </p>
                </motion.div>
                <motion.div
                  animate={{
                    opacity: 1,
                  }}
                  transition={{ duration: 0.5 }}
                  style={{
                    borderRadius: "20px",
                    backgroundColor: "white",
                    padding: "30px",
                    marginTop: "20px",
                    marginBottom: "20px",
                    maxWidth: "400px",
                  }}
                >
                  <p className="text-xl text-center text-black font-[500]">
                    Independent learning with our interactive read along books.
                  </p>
                </motion.div>
              </div>
            </div>
          </motion.div>
        </div>
      </section>
    );
  }

  return (
    <section className="w-full  bg-blue min-h-screen flex justify-center items-center">
      <div className="md:h-[3000px]  max-w-6xl " ref={containerRef}>
        <motion.div className="sticky top-0">
          <div className="w-full md:h-screen flex flex-col md:flex-row">
            <div className="flex-1 h-full flex justify-center items-center">
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3 }}
              >
                <motion.img
                  key={sectionIndex} // This ensures the image is re-rendered when the sectionIndex changes
                  src={images[sectionIndex]}
                  className="md:w-[50vw] w-full"
                  alt="Product Demo"
                  initial={{ opacity: 0 }} // Start with opacity 0
                  animate={{ opacity: 1 }} // Animate to opacity 1
                  exit={{ opacity: 0 }} // Exit with opacity 0
                  transition={{ duration: 0.5 }} // Duration of the fade animation
                />
              </motion.div>
            </div>
            <div className="flex-1 h-full flex flex-col justify-center items-center">
              <motion.div>
                <h1 className="text-3xl text-center text-white md:text-5xl mb-10 font-[600]">
                  Screen time with purpose
                </h1>
              </motion.div>
              <motion.div
                animate={{
                  opacity: sectionIndex === 0 ? 1 : 0.4,
                }}
                transition={{ duration: 0.5 }}
                style={{
                  position: "relative",
                  borderRadius: "20px",
                  backgroundColor: "white",
                  padding: "30px",
                  marginTop: "20px",
                  marginBottom: "20px",
                  maxWidth: "400px",
                }}
              >
                <img
                  src="https://images.readwithjoey.com/joey/website/safe_fun.webp"
                  alt="Safe and Fun"
                  className="hidden md:block"
                  style={{
                    position: "absolute",
                    top: "-20px",
                    left: "-50px",
                    width: "100px",
                    rotate: "10deg",
                  }}
                />

                <p className="text-xl text-center text-black font-[500]">
                  Safe, fun, and crafted just for kids aged 3-7!
                </p>
              </motion.div>
              <motion.div
                animate={{
                  opacity: sectionIndex === 1 ? 1 : 0.4,
                }}
                transition={{ duration: 0.5 }}
                style={{
                  position: "relative",
                  borderRadius: "20px",
                  backgroundColor: "white",
                  padding: "30px",
                  marginTop: "20px",
                  marginBottom: "20px",
                  maxWidth: "400px",
                }}
              >
                <img
                  src="https://images.readwithjoey.com/joey/website/approved.webp"
                  alt="Approved"
                  className="hidden md:block"
                  style={{
                    position: "absolute",
                    top: "-20px",
                    left: "-50px",
                    width: "100px",
                    rotate: "-10deg",
                  }}
                />
                <p className="text-xl text-center text-black font-[500]">
                  Approved by teachers, speech pathologists and paediatricians.
                </p>
              </motion.div>
              <motion.div
                animate={{
                  opacity: sectionIndex === 2 ? 1 : 0.4,
                }}
                transition={{ duration: 0.5 }}
                style={{
                  position: "relative",
                  borderRadius: "20px",
                  backgroundColor: "white",
                  padding: "30px",
                  marginTop: "20px",
                  marginBottom: "20px",
                  maxWidth: "400px",
                }}
              >
                <img
                  src="https://images.readwithjoey.com/joey/website/explore_learn.webp"
                  alt="Explore and Learn"
                  className="hidden md:block"
                  style={{
                    position: "absolute",
                    top: "-20px", // Adjust to position half outside vertically
                    left: "-50px", // Adjust to position half outside horizontally
                    width: "100px", // Adjust the size as needed
                    rotate: "-35deg", // Adjust the rotation as needed
                  }}
                />
                <p className="text-xl text-center text-black font-[500]">
                  Independent learning with our interactive read along books.
                </p>
              </motion.div>
            </div>
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default ProductSectionList;
