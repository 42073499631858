// RandomBookGenerator.jsx
import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import books from "../../models/book";
import ReactConfetti from "react-confetti";
import { useWindowSize } from "react-use";

const RandomBookGenerator = () => {
  const [currentBook, setCurrentBook] = useState(null);
  const [rolling, setRolling] = useState(true);
  const [showConfetti, setShowConfetti] = useState(false);
  const { width, height } = useWindowSize();

  useEffect(() => {
    let timerId;
    let counter = 0;
    const maxCounter = 30; // Increased for longer quick phase
    const initialInterval = 50; // Start with 50ms delay
    const finalInterval = 200; // End with 800ms delay

    const rollBooks = () => {
      // Adjusted easing function
      const shuffledBooks = books.sort(() => Math.random() - 0.5);
      const t = counter / maxCounter;
      const easeOutQuart = 1 - Math.pow(1 - t, 4);
      const currentInterval =
        initialInterval + easeOutQuart * (finalInterval - initialInterval);

      timerId = setTimeout(() => {
        const randomIndex = Math.floor(Math.random() * shuffledBooks.length);
        setCurrentBook(shuffledBooks[randomIndex]);
        counter++;

        if (counter >= maxCounter) {
          setRolling(false); // Animation finished
          setShowConfetti(true); // Start confetti
          // Stop confetti after 3 seconds
          setTimeout(() => {
            setShowConfetti(false);
          }, 12000);
        } else {
          rollBooks(); // Recursively call rollBooks with the next interval
        }
      }, currentInterval);
    };

    rollBooks();

    return () => clearTimeout(timerId);
  }, []);

  if (!currentBook) {
    return (
      <div className="flex items-center justify-center h-screen">
        <p className="text-xl">Loading...</p>
      </div>
    );
  }

  return (
    <div className="flex items-center flex-col justify-center h-screen">
      <div>
        <AnimatePresence wait>
          <motion.div
            key={currentBook.id}
            animate={{ scale: 1, opacity: 1, rotate: 0 }}
            transition={{ duration: rolling ? 0.2 : 0.5 }}
            className="flex flex-col items-center"
          >
            <img
              src={"/covers/width/" + currentBook.cover}
              alt={currentBook.title}
              className="h-60 object-cover rounded-lg shadow-lg"
            />
            <h2 className="text-center text-2xl mt-4">{currentBook.title}</h2>
          </motion.div>
        </AnimatePresence>
      </div>
      {showConfetti && <ReactConfetti width={width} height={height} />}
    </div>
  );
};

export default RandomBookGenerator;
