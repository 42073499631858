import React from "react";

const TestingTasks = () => {
  return (
    <>
      <h2
        id="testing-tasks"
        className="font-sans font-extrabold break-normal text-gray-700 px-2 pb-8 text-xl"
      >
        Testing Tasks
      </h2>
      <p className="text-gray-700 text-base mb-6">
        Please complete the following tasks over the{" "}
        <span className="font-semibold">next 7 days</span>:
      </p>

      <div className="p-8 mt-6 lg:mt-0 leading-normal rounded shadow bg-white mb-12">
        {/* Task 1 */}
        <h3 className="font-[600] text-gray-900 text-xl mb-6 mt-3">
          Task 1: Onboarding Experience
        </h3>
        <p className="text-gray-700 text-base mb-2">
          <strong>Objective:</strong> Evaluate the signup and onboarding
          process.
        </p>
        <p className="text-gray-700 text-base mb-2">
          <strong>Instructions:</strong>
        </p>
        <ul className="list-disc list-inside text-gray-700 text-base mb-4">
          <li>Launch the app and create a new account.</li>
          <li>Create a child.</li>
        </ul>
        <p className="text-gray-700 text-base mb-2">
          <strong>Considerations:</strong>
        </p>
        <ul className="list-disc list-inside text-gray-700 text-base mb-6">
          <li>Was the process straightforward?</li>
          <li>Were instructions clear?</li>
        </ul>
      </div>
      <div className="p-8 mt-6 lg:mt-0 leading-normal rounded shadow bg-white mb-12">
        {/* Task 2 */}
        <h3 className="font-[600] text-gray-900 text-xl mb-6 mt-3">
          Task 2: Subscribing to Joey Books
        </h3>
        <p className="text-gray-700 text-base mb-2">
          <strong>Objective:</strong> Test the subscription process to access
          the library.
        </p>
        <p className="text-gray-700 text-base mb-2">
          <strong>Instructions:</strong>
        </p>
        <ul className="list-disc list-inside text-gray-700 text-base mb-4">
          <li>Log into Joey Books.</li>
          <li>Choose a subscription plan.</li>
          <li>
            Complete the payment process.
            <ul className="list-disc list-inside ml-6">
              <li>
                <strong>YOU WILL NOT BE CHARGED!</strong>
              </li>
              <li>
                See{" "}
                <a href="/beta/faq" className="text-blue underline">
                  FAQ
                </a>{" "}
                for more details.
              </li>
            </ul>
          </li>
        </ul>
        <p className="text-gray-700 text-base mb-2">
          <strong>Considerations:</strong>
        </p>
        <ul className="list-disc list-inside text-gray-700 text-base mb-6">
          <li>Was the pricing and plans clear?</li>
          <li>What did you think about the cost?</li>
          <li>Did you encounter any issues during payment?</li>
        </ul>
      </div>
      <div className="p-8 mt-6 lg:mt-0 leading-normal rounded shadow bg-white mb-12">
        {/* Task 3 */}
        <h3 className="font-[600] text-gray-900 text-xl mb-6 mt-3">
          Task 3: Exploring the Library
        </h3>
        <p className="text-gray-700 text-base mb-2">
          <strong>Objective:</strong> Assess the library navigation and book
          selection.
        </p>
        <p className="text-gray-700 text-base mb-2">
          <strong>Instructions:</strong>
        </p>
        <ul className="list-disc list-inside text-gray-700 text-base mb-4">
          <li>Browse through the book categories.</li>
          <li>Find the book we requested you read.</li>
        </ul>
        <p className="text-gray-700 text-base mb-2">
          <strong>Considerations:</strong>
        </p>
        <ul className="list-disc list-inside text-gray-700 text-base mb-6">
          <li>Is it easy to find books?</li>
          <li>Are the categories helpful?</li>
        </ul>
      </div>
      <div className="p-8 mt-6 lg:mt-0 leading-normal rounded shadow bg-white mb-12">
        {/* Task 4 */}
        <h3 className="font-[600] text-gray-900 text-xl mb-6 mt-3">
          Task 4: Reading a Book
        </h3>
        <p className="text-gray-700 text-base mb-2">
          <strong>Objective:</strong> Test the reading interface and features.
        </p>
        <p className="text-gray-700 text-base mb-2">
          <strong>Instructions:</strong>
        </p>
        <ul className="list-disc list-inside text-gray-700 text-base mb-4">
          <li>
            {" "}
            Find a random book{" "}
            <a
              href="/books/random"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue underline"
            >
              here
            </a>{" "}
            and read it entirely.
          </li>

          <li>
            Try both Read-To-Me and Read-By-Myself features.
            <ul className="list-disc list-inside ml-8 mt-1">
              <li>
                <span>
                  Something wrong with book?{" "}
                  <a
                    href="https://images.readwithjoey.com/joey/website/assets/book_issue.mp4"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue underline"
                  >
                    Report in app
                  </a>
                </span>
              </li>
            </ul>
          </li>
        </ul>
        <p className="text-gray-700 text-base mb-2">
          <strong>Considerations:</strong>
        </p>
        <ul className="list-disc list-inside text-gray-700 text-base mb-6">
          <li>Is the text legible?</li>
          <li>Do interactive elements enhance the experience?</li>
        </ul>
      </div>
      <div className="p-8 mt-6 lg:mt-0 leading-normal rounded shadow bg-white mb-12">
        {/* Task 5 */}
        <h3 className="font-[600] text-gray-900 text-xl mb-6 mt-3">
          Task 5: Performance Check
        </h3>
        <p className="text-gray-700 text-base mb-2">
          <strong>Objective:</strong> Identify any technical issues.
        </p>
        <p className="text-gray-700 text-base mb-2">
          <strong>Instructions:</strong>
        </p>
        <ul className="list-disc list-inside text-gray-700 text-base mb-4">
          <li>Use the app at different times and network conditions.</li>
        </ul>
        <p className="text-gray-700 text-base mb-2">
          <strong>Considerations:</strong>
        </p>
        <ul className="list-disc list-inside text-gray-700 text-base mb-6">
          <li>Did you experience any crashes or slowdowns?</li>
          <li>How is the app's load speed?</li>
        </ul>
      </div>
      <div className="p-8 mt-6 lg:mt-0 leading-normal rounded shadow bg-white mb-12">
        {/* Task 6 */}
        <h3 className="font-[600] text-gray-900 text-xl mb-6 mt-3">
          Task 6: Parental Controls (optional)
        </h3>
        <p className="text-gray-700 text-base mb-2">
          <strong>Objective:</strong> Review the parental control settings.
        </p>
        <p className="text-gray-700 text-base mb-2">
          <strong>Instructions:</strong>
        </p>
        <ul className="list-disc list-inside text-gray-700 text-base mb-4">
          <li>Access the settings menu.</li>
          <li>Explore parental control options.</li>
          <li>Switch between your children's profiles.</li>
          <li>Create a new child.</li>
        </ul>
        <p className="text-gray-700 text-base mb-2">
          <strong>Considerations:</strong>
        </p>
        <ul className="list-disc list-inside text-gray-700 text-base mb-6">
          <li>Are settings intuitive?</li>
        </ul>
      </div>
    </>
  );
};

export default TestingTasks;
