import Footer from "./components/Footer";
import Home from "./pages/Home";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsConditions from "./pages/TermsConditions";
// import Beta from "./pages/Beta";
// import BetaNavbar from "./components/Beta/BetaNavbar";
import { AudioProvider } from "./state/audioContext";
import BetaTestingGuide from "./pages/BetaTestingGuide";
import BetaTestingFAQ from "./pages/BetaFAQ";
import RandomBook from "./pages/randomBook";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <>
          {/* <Navbar /> */}
          <Home />
          <Footer />
        </>
      ),
    },

    {
      path: "/privacy-policy",
      element: (
        <div className="flex w-full flex-1 flex-col bg-white">
          <PrivacyPolicy />
          <Footer />
        </div>
      ),
    },
    {
      path: "/terms-of-service",
      element: (
        <div className="flex w-full flex-1 flex-col bg-white">
          <TermsConditions />
          <Footer />
        </div>
      ),
    },
    {
      path: "/beta/guide",
      element: <BetaTestingGuide />,
    },
    {
      path: "/beta/faq",
      element: <BetaTestingFAQ />,
    },
    {
      path: "/books/random",
      element: <RandomBook />,
    },
    {
      path: "*",
      element: (
        <>
          {/* <Navbar /> */}
          <Home />
          <Footer />
        </>
      ),
    },
  ]);
  return (
    <AudioProvider>
      <RouterProvider router={router} />
    </AudioProvider>
  );
}

export default App;
