class Testimonial {
  constructor(name, content, profileImage, credentials) {
    this.name = name;
    this.content = content;
    this.profileImage = profileImage;
    this.credentials = credentials;
  }
}

const testimonials = [
  new Testimonial(
    "Amanda",
    "I love the narrator's voices and that it highlights the words to follow along. Super easy to use and Paloma was able to navigate and choose easily.",
    "Amanda.jpg",
    "Mother of two children aged 2 and 4"
  ),

  new Testimonial(
    "Ben",
    "My children love reading independently with Joey. It’s so easy to use!",
    "Ben.jpg",
    "Father of 4 children aged 2, 4, 6 and 8"
  ),
  new Testimonial(
    "Indianna",
    "Joey Books is the only app I will allow my 4-year-old son to use.",
    "Indianna2.jpg",
    "Occupational Therapist & mother of two"
  ),

  new Testimonial(
    "Bianca",
    "At Kid Speak we work with a range of children who experience challenges developing their early language and literacy skills. We recommend parents expose their children to language rich experiences, such as book sharing. Joey Books provides a great option for busy parents who want to replicate this experience.",
    "Bianca.jpg",
    "Principal Speech Pathologist, Kids Speak"
  ),
];

export const children_favorites_images = [
  "profiles/child-water-witcher.png",
  "profiles/child-noisy-baby.png",
  "profiles/child-mermaid.png",
];

export default testimonials;
