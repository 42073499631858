import React from "react";

const TestingIntroduction = () => {
  return (
    <div class="container w-full flex flex-wrap mx-auto px-2 pt-3 lg:pt-4 ">
      <section class="w-full lg:w-4/5 mb-4">
        <h1 className="md:text-2xl sm:text-xl text-lg font-bold text-left mt-10">
          Thank you for participating in the beta testing of Joey Books!
        </h1>
        <p className="italic">Ending 30th September.</p>
        <p className="text-left mt-5 font-[500]">
          Your feedback is crucial in helping us create an engaging and
          educational reading experience for children.{" "}
        </p>
        <p className="text-left mt-5 ml-5 font-[500]">
          FAQs available{" "}
          <a href="/beta/faq" className="text-blue hover:underline">
            here
          </a>
          .
        </p>
        <p className="text-left mt-2 ml-5 font-[500]">
          Feedback questionnaire available{" "}
          <a
            href="https://41ys65jlltc.typeform.com/to/aeVBXBYV"
            className="text-blue hover:underline font-bold"
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>
          .
        </p>
      </section>
    </div>
  );
};

export default TestingIntroduction;
