import React from "react";

const TestingIntroductionSection = () => {
  return (
    <>
      <h2
        id="introduction"
        class="font-sans font-extrabold break-normal text-gray-700 px-2 pb-8 text-xl "
      >
        Introduction
      </h2>
      <div class="p-8 mt-6 lg:mt-0 leading-normal rounded shadow bg-white mb-12">
        <h4 class="font-[600] break-normal text-gray-900 text-xl mb-2">
          About Joey Books
        </h4>
        <p class="text-gray-700 text-base mb-6">
          Joey Books is an online children's subscription book service that
          offers immediate, on-demand access to a vast library of high-quality
          illustrated books for children aged 3 to 7 years old. Our
          ever-expanding library features Read-to-Me books that beautifully
          replicates the experience of a parent reading to a child, fostering
          early literacy skills and a lifelong love of reading. Children can
          also choose to read independently, enhancing their reading proficiency
          and confidence by listening to each page after they have read it.
          Through partnerships with leading publishers, we bring
          age-appropriate, award-winning books to your child, supporting their
          literacy development and nurturing their imagination.
        </p>
        <h4 class="font-[600] break-normal text-gray-900 text-xl mb-2 mt-6">
          Purpose of Beta Testing
        </h4>
        <p class="text-gray-700 text-base mb-6 mt-6">
          We aim to evaluate:
          <ul className="list-disc list-inside text-gray-700 text-base mb-6 mt-6">
            <li>
              <span class="font-semibold">Usability:</span> How easily can users
              navigate the app?
            </li>
            <li>
              {" "}
              <span class="font-semibold">Functionality:</span> Do all features
              work as intended?
            </li>
            <li>
              {" "}
              <span class="font-semibold">Engagement:</span> Is the content
              engaging for children?
            </li>
            <li>
              {" "}
              <span class="font-semibold">Performance:</span> Are there any bugs
              or crashes?
            </li>
          </ul>
        </p>
      </div>
    </>
  );
};

export default TestingIntroductionSection;
