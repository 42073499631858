import React from "react";
import TestingTitle from "../components/BetaTesting/testingTitle";
import ContentTable from "../components/BetaTesting/contentTable";
import TestingIntroduction from "../components/BetaTesting/testingIntroduction";
import TestingIntroductionSection from "../components/BetaTesting/sections/introduction";
import InstallationSection from "../components/BetaTesting/sections/installation";
import TestingTasks from "../components/BetaTesting/sections/testingTasks";
import TestingFeedback from "../components/BetaTesting/sections/feedback";
import TestingContactUs from "../components/BetaTesting/sections/testingContact";
import TestingThanks from "../components/BetaTesting/sections/testingThanks";

const BetaTestingGuide = () => {
  return (
    <body className="bg-gray-100 min-h-screen text-gray-900 tracking-wider leading-normal  -z-20">
      <TestingTitle />
      <div className="container w-full mx-auto px-2 pt-8 lg:pt-8 mt-8 ">
        {/* Sidebar for large screens */}
        <div className="hidden lg:block fixed w-1/5 px-6 text-xl text-gray-800 leading-normal   ">
          <ContentTable />
        </div>

        {/* Main content area */}
        <div className="flex flex-col lg:ml-[20%]">
          <div className="w-full">
            <TestingIntroduction />
            <div className="block lg:hidden order-2 w-full px-6 text-xl text-gray-800 leading-normal">
              <ContentTable />
            </div>
            <hr className="bg-gray-300 my-12" />
            {/* Sections go here */}
            <TestingIntroductionSection />
            <hr className="bg-gray-300 my-12" />
            <InstallationSection />
            <hr className="bg-gray-300 my-12" />
            <TestingTasks />
            <hr className="bg-gray-300 my-12" />
            <TestingFeedback />
            <hr className="bg-gray-300 my-12" />
            <TestingContactUs />
            <hr className="bg-gray-300 my-12" />
            <TestingThanks />
          </div>
        </div>
      </div>
      <footer className="bg-blue w-full flex items-center h-full justify-center shadow rounded-t-lg">
        <img
          src="/assets/JoeyFooter.jpg"
          alt="Joey Footer"
          className="md:h-[200px] object-contain"
        />
      </footer>
    </body>
  );
};
export default BetaTestingGuide;
