import React from "react";

const InstallationSection = () => {
  return (
    <>
      <h2
        id="installation"
        class="font-sans font-extrabold break-normal text-gray-700 px-2 pb-8 text-xl "
      >
        Installation
      </h2>
      <div class="p-8 mt-6 lg:mt-0 leading-normal rounded shadow bg-white mb-12">
        <h4 class="font-[500] break-normal text-gray-900 text-xl mb-2">
          For iOS Users
        </h4>
        <ul className="list-decimal list-inside text-gray-700 text-base mb-6 mt-6">
          <li>
            <span class="font-semibold">Install TestFlight:</span> Download the
            TestFlight app from the{" "}
            <a
              href="https://apps.apple.com/us/app/testflight/id899247664"
              className="text-blue underline"
            >
              App Store.
            </a>
          </li>
          <li>
            <span class="font-semibold">Access the Joey Books Beta App: </span>
            Click on this invitation link on your iOS device:{" "}
            <a
              href="https://testflight.apple.com/join/4xmM6n5p"
              className="text-blue underline"
            >
              {" "}
              Click here.
            </a>
          </li>
          <li>
            <span class="font-semibold">Install Joey Books: </span> Follow the
            on-screen instructions to install the app.
            <ul className="list-disc list-inside ml-8">
              <li>
                <span>
                  Having trouble?{" "}
                  <a
                    href="https://images.readwithjoey.com/joey/website/assets/ios_downloading_joey.mp4"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue underline"
                  >
                    Demo video
                  </a>
                </span>
              </li>
            </ul>
          </li>
        </ul>
        <hr class="bg-gray-300 my-12" />

        <h4 class="font-[500] break-normal text-gray-900 text-xl mb-2 mt-6">
          For Android Users
        </h4>
        <p class="text-gray-700 text-base mb-6 mt-6">
          <ul className="list-decimal list-inside text-gray-700 text-base mb-6 mt-6">
            <li>
              <span class="font-semibold">Install Joey Books: </span> Install
              the app from the{" "}
              <a
                href="https://play.google.com/store/apps/details?id=com.readwithjoey.joey_books"
                className="text-blue underline"
              >
                Google Play Store.
              </a>
            </li>
          </ul>
        </p>
      </div>
    </>
  );
};

export default InstallationSection;
