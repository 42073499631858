import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";

const FAQContentTable = () => {
  const [activeId, setActiveId] = useState("");
  const [isHelpMenuVisible, setHelpMenuVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const scrollItems = document.querySelectorAll("#menu-content a");
      const fromTop = window.scrollY + 50; // Adjust top offset as needed

      let lastActive = "";

      scrollItems.forEach((item) => {
        const section = document.querySelector(item.getAttribute("href"));
        if (section && section.offsetTop <= fromTop) {
          lastActive = item.getAttribute("href").substring(1);
        }
      });

      setActiveId(lastActive);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleClick = (e, id) => {
    e.preventDefault();
    const section = document.querySelector(id);
    const topMenuHeight = 50; // Adjust based on your top menu height
    const offsetTop = section ? section.offsetTop - topMenuHeight + 1 : 0;

    window.scrollTo({
      top: offsetTop,
      behavior: "smooth",
    });

    if (isHelpMenuVisible) {
      setHelpMenuVisible(false); // Hide the help menu if it's visible
    }
  };

  return (
    <>
      <p className="text-base font-bold py-2 lg:pb-6 text-gray-700">
        Table of Contents
      </p>

      <div
        className="w-full  lg:h-auto overflow-x-hidden overflow-y-auto lg:overflow-y-hidden lg:block mt-0 my-2 lg:my-0 border-transparent shadow-none lgbg-transparent z-20"
        id="menu-content"
      >
        <ul className="list-decimal lg:list-none list-inside py-2 md:py-0 text-sm">
          {["subscribing", "techincal-support", "contact-us"].map((id) => (
            <motion.li
              key={id}
              className={`py-1 md:my-2 hover:bg-yellow-100 lg:hover:bg-transparent border-l-4 ${
                activeId === id
                  ? "font-bold border-yellow-600"
                  : "border-transparent"
              }`}
            >
              <a
                href={`#${id}`}
                className="pl-4 align-middle text-gray-700 no-underline hover:text-yellow"
                onClick={(e) => handleClick(e, `#${id}`)}
              >
                <span className="pb-1 md:pb-0 text-sm capitalize">
                  {id.replace("-", " ")}
                </span>
              </a>
            </motion.li>
          ))}
        </ul>
      </div>

      {/* Example Help Menu */}
      <div id="helpMenuDiv" className={isHelpMenuVisible ? "block" : "hidden"}>
        {/* Help menu content */}
      </div>
    </>
  );
};

export default FAQContentTable;
