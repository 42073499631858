import React from "react";
import Title from "../components/Home/title/Title";
import UserStory from "../components/Home/userStory/UserStory";
import ProductSectionList from "../components/Home/product/product_list";
import Call2Action from "../components/Home/call2action/call_to_action";
import HorizontalTestimonials from "../components/Home/testimonials/testiminial";
import PublisherList from "../components/Home/publishers/publisher_list";

const Home = () => {
  return (
    <main className="flex-1 bg-white text-black">
      <Title />
      <UserStory />
      <PublisherList />
      <ProductSectionList />
      <HorizontalTestimonials />
      <Call2Action />
    </main>
  );
};

export default Home;
