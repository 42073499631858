import React from "react";

import RandomBookGenerator from "../components/randomBook/bookSelection";

const RandomBook = () => {
  return (
    <main className="flex-1 bg-white text-black">
      <RandomBookGenerator />
    </main>
  );
};

export default RandomBook;
