import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { motion } from "framer-motion";
import { useAudioContext } from "../../../state/audioContext";
import { FaPlayCircle, FaPauseCircle } from "react-icons/fa";
import { FaRegPauseCircle, FaRegPlayCircle } from "react-icons/fa";

const BookCoverTitle = ({
  coverImage,
  height,
  mobile_height,
  position_x,
  mobile_position_x,
  position_y,
  mobile_position_y,
  mobile_radius,
  radius,
  animate,
  book,
}) => {
  const { audioRefs, currentAudio, handlePlayPause } = useAudioContext();

  const [isHovered, setIsHovered] = useState(false);
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  if (isMobile && mobile_position_x === 0 && mobile_position_y === 0) {
    return <></>;
  }
  mobile_position_x = mobile_position_x + 50;
  const translateX = isMobile
    ? mobile_position_x - mobile_height
    : position_x - height;
  const translateY = isMobile
    ? mobile_position_y - mobile_height
    : position_y - height;

  return (
    <motion.div
      initial={{ scale: 1 }}
      animate={{ y: translateY, x: translateX, scale: 0.9 }}
      transition={{
        duration: 1.5,
        delay: 1.2,
        ease: [0.15, 0.85, 0.45, 1],
      }}
      className={`absolute md:h-[${height} h-[${mobile_height}]`}
    >
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          duration: 0,
          delay: 1.2,
        }}
      >
        <motion.a
          whileHover={{ scale: 1.2 }}
          whileTap={isMobile ? { scale: 1.1 } : {}}
          onClick={(e) => {
            if (book.audio_sample === "") return;
            handlePlayPause(
              "https://audio.readwithjoey.com/joey/public/samples/" +
                book.audio_sample
            );
          }}
          onHoverStart={(e) => {
            setIsHovered(true);
          }}
          onHoverEnd={(e) => {
            setIsHovered(false);
          }}
          className="relative h-full w-full flex justify-center items-center"
        >
          <motion.img
            src={coverImage}
            alt="Book Cover"
            className="object-contain h-[100px] rounded-lg"
            whileHover={{
              scale: 1.2,
              transition: { duration: 0.2 },
            }}
          />
          {/* Play button */}
          {book.audio_sample !== "" && (
            <motion.div
              className="absolute inset-0 flex items-center justify-center pointer-events-none drop-shadow-lg"
              style={{ opacity: isHovered ? 1 : 0 }}
            >
              {currentAudio ===
              "https://audio.readwithjoey.com/joey/public/samples/" +
                book.audio_sample ? (
                <FaRegPauseCircle fill="#f5f4f6" size={32} />
              ) : (
                <FaRegPlayCircle fill="#f5f4f6" size={32} />
              )}
            </motion.div>
          )}
        </motion.a>
      </motion.div>
    </motion.div>
  );
};

export default BookCoverTitle;
