import React from "react";

const FAQIntro = () => {
  return (
    <div class="container w-full flex flex-wrap mx-auto px-2 pt-3 lg:pt-4 ">
      <section class="w-full lg:w-4/5 mb-4">
        <h1 className="md:text-2xl sm:text-xl text-lg font-bold text-left mt-10">
          Thank you for participating in the beta testing of Joey Books!
        </h1>
        <p className="text-left mt-5 font-[500]">
          This FAQ document is designed to help you with common questions and
          issues that might arise during testing. If you need further
          assistance, please don't hesitate to contact us.
        </p>
        <p className="text-left mt-5 font-[500]">
          Testing guide available{" "}
          <a href="/beta/guide" className="text-blue hover:underline">
            here
          </a>
          .
        </p>
      </section>
    </div>
  );
};

export default FAQIntro;
